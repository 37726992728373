import React from 'react';
import styled from 'styled-components';
import {
    LightTheme,
    DarkTheme,
    // JadeTheme,
    MonoTheme,
} from '../components/Theme';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Intro from '../components/Intro';
import HomeCTA from '../components/HomeCTA';
import SecondaryCTAs from '../components/SecondaryCTAs';
import Events from '../components/Events';
// import FeaturedDownloads from '../components/FeaturedDownloads';
import Footer from '../components/Footer';
import Metadata from '../components/Metadata';
import useHomePage from '../utils/useHomePageQuery';
import notNull from '../utils/notNull';

const Section = styled.div`
    display: grid;
    grid-template-columns: 100%;

    &[data-section='banner'] {
        grid-row-gap: var(--sectionSpacing10);
    }

    &[data-section='intro'] {
        grid-row-gap: var(--sectionSpacing11);
    }

    &[data-section='events'] {
        padding: var(--sectionSpacing10) 0;
    }

    &[data-section='downloads'] {
        padding: var(--sectionSpacing11) 0;
        grid-row-gap: var(--sectionSpacing11);
    }
`;

export default function Index(): React.ReactElement {
    const { banner, intro, sideBySideCTAs, pageMetadata } = useHomePage();

    const filteredCTAs = sideBySideCTAs && sideBySideCTAs.filter(notNull);

    return (
        <React.Fragment>
            {pageMetadata && <Metadata pageMetadata={pageMetadata} />}
            <DarkTheme>
                <Navbar />
                <Section data-section="banner">
                    {banner && <Banner {...banner} />}
                    <Section data-section="intro">
                        {intro && <Intro contentRaw={intro} />}
                        <HomeCTA />
                        {filteredCTAs && filteredCTAs.length > 0 && (
                            <SecondaryCTAs ctas={filteredCTAs} />
                        )}
                    </Section>
                </Section>
            </DarkTheme>
            <MonoTheme>
                <Section data-section="events">
                    <Events />
                </Section>
            </MonoTheme>
            <LightTheme>
                <Footer />
            </LightTheme>
        </React.Fragment>
    );
}
