import React from 'react';
import { Link as _Link } from 'gatsby';
import styled from 'styled-components';
import { Viewport } from '../Settings';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(24, 1fr);

    @media (max-width: ${Viewport.Mobile}px) {
        display: block;
        padding: 0 var(--sizeGutters);
    }
`;

const Inner = styled.div`
    grid-column: 9 / span 13;
    font-size: var(--size7);
    line-height: var(--lineHeightMed);
    color: var(--foregroundColor);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 0.5em;

    @media (max-width: ${Viewport.TabletPortrait}px) {
        font-size: var(--size6);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size5);
    }
`;

const Link = styled(_Link)`
    color: var(--highlightColor);
    text-decoration: underline;
`;

function HomeCTA(): React.ReactElement {
    return (
        <Container>
            <Inner>
                <p>Looking to go on tour?</p>
                <p>Need funding?</p>
                <p>Resolution assistance?</p>
                <Link to="/resources/">Check out our resources</Link>
            </Inner>
        </Container>
    );
}

export default React.memo(HomeCTA);
