import { graphql, useStaticQuery } from 'gatsby';
import {
    HomePageQuery,
    HomePageQuery_sanityHomePage as HomePage,
} from '../__generated__/HomePageQuery';

export default function useHomePageQuery(): HomePage {
    const query: HomePageQuery = useStaticQuery(graphql`
        query HomePageQuery {
            sanityHomePage(_id: { eq: "homePage" }) {
                pageMetadata {
                    ...PageMetadataFragment
                }
                banner {
                    ...BannerFragment
                }
                intro: _rawIntro
                sideBySideCTAs {
                    ...SideBySideCTAFragment
                }
                events {
                    _key
                    title
                    image {
                        ...ImageFragment
                    }
                    subtitle
                    link {
                        url
                    }
                }
            }
        }
    `);

    if (query.sanityHomePage) {
        return query.sanityHomePage;
    }

    throw new Error('Unfound HomePage');
}
