import React from 'react';
import styled from 'styled-components';
import notNull from '../utils/notNull';
import useHomePage from '../utils/useHomePageQuery';
import { Viewport } from '../Settings';

const Container = styled.div`
    --foregroundColor(--black);
    --backgroundColor(--white);
    --highlightColor(--gray);
`;

const Title = styled.div`
    width: calc(7 / 24 * 100%);
    padding-left: calc(3 / 24 * 100%);

    font-size: 50px;
    line-height: 1.1;
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.Mobile}px) {
        padding: 0 var(--sizeGutters);
        width: auto;
    }
`;

const Inner = styled.div`
    margin-top: var(--size6);
    --rowSpacing: var(--size6);
    margin-bottom: calc(-1 * var(--rowSpacing));
    columns: 2;
    column-gap: calc(100vw / 24 * 3);

    padding: 0 calc(100vw / 24 * 3);

    @media (max-width: ${Viewport.Mobile}px) {
        padding: 0 var(--sizeGutters);
        columns: 1;
    }
`;

const Item = styled.div`
    display: inline-block;
    margin-bottom: var(--rowSpacing);
    width: 100%;
`;

const Link = styled.a`
    display: block;
`;

const Hed = styled.div`
    margin-top: var(--size4);
    font-size: var(--size4);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: var(--foregroundColor);
`;

const Dek = styled.div`
    margin-top: var(--size2);
    color: var(--foregroundColor);
`;

const Path = styled.div`
    margin-top: var(--size2);
    color: var(--highlightColor);
`;

const Img = styled.img`
    display: block;
    width: 100%;
    height: auto;
`;

const printLink = (link: string): string =>
    link
        .replace(/https?:\/\//, '') // the https:// or http://
        .replace(/\?.*/, '') // everything after (and including) '?'
        .replace(/\/$/, '') // trailing slash
        .trim();

function Events(): React.ReactElement {
    const { events } = useHomePage();
    return (
        <Container>
            <Title>{`Friends, partners & events`}</Title>
            <Inner>
                {events &&
                    events.filter(notNull).map(
                        ({
                            _key: key,
                            link,
                            title,
                            subtitle,
                            image,
                        }): React.ReactElement => (
                            <Item key={String(key)}>
                                {link && link.url && (
                                    <Link href={link.url}>
                                        {image &&
                                            image.asset &&
                                            image.asset.url && (
                                                <Img src={image.asset.url} />
                                            )}
                                        {title && <Hed>{title}</Hed>}
                                        {subtitle && <Dek>{subtitle}</Dek>}
                                        <Path>{printLink(link.url)}</Path>
                                    </Link>
                                )}
                            </Item>
                        )
                    )}
            </Inner>
        </Container>
    );
}

export default React.memo(Events);
